// extracted by mini-css-extract-plugin
export var comment = "blog-details-module--comment--qlzGu";
export var commentBox = "blog-details-module--commentBox--X7Cc7";
export var commentHead = "blog-details-module--commentHead--YsDpM";
export var commenter = "blog-details-module--commenter--VLfAi";
export var commentsDiv = "blog-details-module--comments-div--zMNvM";
export var commentsDivs = "blog-details-module--commentsDivs--r6CWo";
export var date = "blog-details-module--date--Jx5hn";
export var iframeYtContainer = "blog-details-module--iframeYtContainer--hrSJF";
export var input = "blog-details-module--input--5GUjA";
export var mainBlog = "blog-details-module--mainBlog--kFJMX";
export var submitBtn = "blog-details-module--submitBtn--syq55";
export var txtarea = "blog-details-module--txtarea--zud17";
export var txtinput = "blog-details-module--txtinput--QQHMs";
export var username = "blog-details-module--username--BJ9Ba";
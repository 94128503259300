import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import "bulma/css/bulma.css";
import Layout from "../components/Layout";
import * as styles from "./blog-details.module.css";
import { FaArrowLeft } from "react-icons/fa";
import { Helmet } from "react-helmet";
import Recaptcha from "react-recaptcha";
import { FiUser, FiMail } from "react-icons/fi";
import {
  doc,
  setDoc,
  getDoc,
  getFirestore,
  arrayUnion,
} from "firebase/firestore";
const BlogDetails = ({ data, location }) => {
  const blog = data.markdownRemark;
  const icon = {
    fontSize: "18px",
    marginTop: "2px",
    marginRight: "5px",
  };

  const [videoURL, setVideoURL] = useState();
  useEffect(() => {
    if (blog.frontmatter.videoURL) {
      const url = blog.frontmatter.videoURL;
      const id = url.match(
        /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
      );
      setVideoURL("https://www.youtube.com/embed/" + id[1] + "?rel=0");
    }
  }, [videoURL, blog.frontmatter.videoURL]);

  const [allComments, setAllComments] = useState([]);
  // Load All Comments
  useEffect(() => {
    loadComments();
  }, []);
  const loadComments = async () => {
    let id = blog.fields.slug;
    id = id.replace(/^\/|\/$/g, "");
    const db = getFirestore();
    const docRef = doc(db, "allblogs", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().comments) {
      setAllComments(docSnap.data().comments);
      // setAllComments("Hello");
      setTotalComments(docSnap.data().comments.length);
    }
  };
  // Variable to store contact form details
  const [commentDetails, setCommentDetails] = useState({
    name: null,
    email: null,
    comment: null,
    date: null,
  });
  // Verified variable
  const [isVerified, setIsVerified] = useState(false);

  // Total Comments
  const [totalComments, setTotalComments] = useState(0);
  // Recaptcha code
  const verifyCallback = (res) => {
    if (res) {
      setIsVerified(true);
    }
  };

  //Send function to submit contact form details.
  const sendForm = async (e) => {
    e.preventDefault();
    if (isVerified) {
      commentDetails.date = new Date(Date.now()).toLocaleString().split(",")[0];
      let id = blog.fields.slug;
      id = id.replace(/^\/|\/$/g, "");
      const db = getFirestore();
      const commentRef = doc(db, "allblogs", id);
      await setDoc(
        commentRef,
        {
          comments: arrayUnion(commentDetails),
        },
        { merge: true }
      ).then(() => {
        alert("Thank You for commenting.");
        setTotalComments(totalComments + 1);
        if (allComments.length !== 0) {
          setAllComments([...allComments, commentDetails]);
        } else {
          setAllComments([commentDetails]);
        }
        setCommentDetails({
          name: null,
          email: null,
          comment: null,
          date: null,
        });
        e.target.reset();
        window.grecaptcha.reset();
        //Center the reCaptcha
        document.getElementById("g-recaptcha").children[0].style.margin =
          "auto";
      });
    } else {
      alert("Please verify reCaptcha.");
    }
  };
  return (
    <Layout>
      {/* Meta tags */}
      <Helmet>
        <title> {blog.frontmatter.title}</title>
        <meta name="description" content={blog.frontmatter.title} />
      </Helmet>

      <div className={"mt-6 " + styles.mainBlog}>
        {/* Back to blogs link */}

        <Link
          to="/blog"
          state={{ tag: location.state ? location.state.tag : "" }}
        >
          <div
            className={
              "mt-5 mb-3 has-text-grey has-text-weight-semibold is-flex "
            }
          >
            <div style={icon}>
              <FaArrowLeft />
            </div>
            back to blog
          </div>
        </Link>

        <div className=" is-size-2 is-size-5-mobile has-text-weight-bold">
          {" "}
          {/* Blog title */}
          {blog.frontmatter.title}{" "}
        </div>
        <div className="has-text-right mt-2 has-text-grey">
          {blog.frontmatter.date}{" "}
          {blog.frontmatter.videoURL
            ? ""
            : blog.frontmatter.readTime
            ? "• " + blog.frontmatter.readTime + " min read"
            : "• 5 min read"}
        </div>
        {/* Blog content */}
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          className=" has-text-justified mt-2"
          style={{
            overflowWrap: "break-word",
          }}
        />
        {videoURL ? (
          <div className={styles.iframeYtContainer}>
            <iframe
              src={videoURL}
              width="560"
              height="315"
              title="Youtube Video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* All Comments */}
      {allComments.length !== 0 ? (
        <div className={styles.commentsDivs}>
          <h2>Comments - {"{ " + totalComments + " }"}</h2>
          {allComments.map((comment, i) => (
            <div className={styles.comment} key={i}>
              {/* comment loop */}
              <header>
                <div className={styles.commenter}>
                  <h5 className={styles.username}>{comment.name}</h5>
                  <h5 className={styles.date}>{comment.date}</h5>
                </div>
              </header>
              <p>{comment.comment}</p>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      {/* Comment Box */}
      <section id="contact">
        <div style={{ margin: "100px 20px" }}>
          <div className={styles.commentBox}>
            <h2 className={"has-text-centered " + styles.commentHead}>
              Leave a Comment
            </h2>
            <div
              style={{ color: "#6C63FF", fontWeight: "600" }}
              className="has-text-centered"
            >
              Your email address will not be published
            </div>
            <form onSubmit={sendForm} id="form">
              <div className="mt-5">
                <div className={"columns " + styles.txtinput}>
                  <div className="field column">
                    <label className="label" htmlFor="name">
                      Name *
                    </label>
                    <div className="control has-icons-left">
                      <input
                        className={"input " + styles.input}
                        type="text"
                        id="name"
                        required
                        onChange={(event) =>
                          setCommentDetails({
                            ...commentDetails,
                            name: event.target.value,
                          })
                        }
                      />
                      <span className="icon is-small is-left">
                        <FiUser />
                      </span>
                    </div>
                  </div>
                  <div className="field column">
                    <label className="label" htmlFor="email">
                      Email *
                    </label>
                    <div className="control has-icons-left">
                      <input
                        className={"input " + styles.input}
                        type="email"
                        id="email"
                        required
                        onChange={(event) =>
                          setCommentDetails({
                            ...commentDetails,
                            email: event.target.value,
                          })
                        }
                      />
                      <span className="icon is-small is-left">
                        <FiMail />
                      </span>
                    </div>
                  </div>
                </div>
                <div className={"column "}>
                  <div className="field">
                    <label className="label" htmlFor="comment">
                      Comment *
                    </label>
                    <div className="control">
                      <textarea
                        id="comment"
                        className={
                          "textarea " + styles.input + " " + styles.txtarea
                        }
                        rows="8"
                        style={{ paddingBottom: "3px" }}
                        required
                        onChange={(event) =>
                          setCommentDetails({
                            ...commentDetails,
                            comment: event.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field columns is-vcentered">
                <div className="column">
                  <Recaptcha
                    sitekey="6Lde__EbAAAAAOwSrPdof0BPi5Vh-E4adX4NA0yU"
                    render="explicit"
                    onloadCallback={() => {
                      document.getElementById(
                        "g-recaptcha"
                      ).children[0].style.margin = "auto";
                    }}
                    verifyCallback={verifyCallback}
                    expiredCallback={() => {
                      setIsVerified(false);
                    }}
                  />
                </div>
                <div className="column">
                  <button
                    className={"button is-fullwidth " + styles.submitBtn}
                    type="submit"
                    id="submitBtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogDetails;

export const query = graphql`
  query MyQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        videoURL
        readTime
        date(formatString: "MMMM DD YYYY")
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      html
      fields {
        slug
      }
    }
  }
`;
